.HomeMenu {
    width: 100%;
    height: 100%;
}

.HomeMenuCommunication {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 700;
    text-shadow: 0 0 4px var(--main-green-color);
    color: var(--main-green-color);
    background-color: black;
}

.HomeMenuContactsLinks {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.HomeMenuContactsLinks > a {
    cursor: pointer;
}

.HomeMenuContactsLink {
    width: 30px;
    margin: 5px;
    border-radius: 50%;
    box-shadow: 0 0 7px rgb(51, 51, 51);
    cursor: pointer;
}